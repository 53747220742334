<template>
  <div>
    <v-row align="center">
      <v-col cols="6" sm="6" md="4">
        <v-text-field
          outlined
          dense
          v-model="filters.search"
          prepend-inner-icon="mdi-magnify"
          class="ml-auto filters search"
          :label="$t('search')"
          hide-details
          single-line
          @input="searchFilter"
        />
      </v-col>
      <v-col cols="6" md="4" v-if="count > 0" class="no-wrap">
        <v-btn
          color="red"
          :outlined="filters.min_stock !== true"
          elevation="0"
          height="30px"
          @click="
            filters.min_stock = true;
            reload();
          "
        >
          {{ $t("inventorys.low_stock", { count }) }}
          <v-btn
            v-if="filters.min_stock"
            icon
            color="black"
            @click.stop="
              filters.min_stock = false;
              reload();
            "
            ><v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn v-else icon color="red" @click.stop="clearMinStock"
            ><v-icon small>$delete</v-icon>
          </v-btn>
        </v-btn>
      </v-col>
    </v-row>
    <brand ref="brands" @refresh="fetchInventorys"></brand>
    <category ref="categorys" @refresh="fetchInventorys"></category>
    <inventory ref="inventorys" @refresh="fetchInventorys"></inventory>
    <base-data-table-cards
      id="table_inventory"
      :headers="headers"
      :headers_small="headers_small"
      :data="items"
      :totalItems="totalInventory"
      :title="$t('inventory')"
      @update="fetchInventorys"
    >
      <template v-slot:item_product_image="item">
        <img v-if="item.url" :src="item.url" height="50px" />
        <v-icon v-else color="primary">$camera</v-icon>
      </template>

      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        v-slot:item_product_price="item"
        >{{ $n(item.product_price, "currency") }}
      </template>
      <template v-else v-slot:item_product_price="item">
        <span
          ><b>{{ $n(item.product_price, "currency") }}</b></span
        >
      </template>
      <template v-slot:item_actions="item">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" large>mdi-dots-horizontal</v-icon>
          </template>
          <v-list>
            <v-list-item
              class="invList"
              @click="
                $router.push({
                  name: 'inventoryView',
                  params: { id: item.id },
                })
              "
            >
              <v-list-item-icon> <v-icon small>$eye</v-icon></v-list-item-icon>
              <v-list-item-title>
                <span>{{
                  $t("view", { name: $tc("product") })
                }}</span></v-list-item-title
              >
            </v-list-item>
            <v-list-item class="invList" @click="del(item)">
              <v-list-item-icon>
                <v-icon small>$delete</v-icon></v-list-item-icon
              >
              <v-list-item-title>
                <span>{{
                  $t(!item.inventory_move.length ? "delete" : "archivate", {
                    name: $tc("product"),
                  })
                }}</span></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item_brand_brand_name="item">
        <span v-if="item.brand != null">{{ item.brand.brand_name }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:item_category_category_name="item">
        <span v-if="item.category != null">{{
          item.category.category_name
        }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:item_supplier_supplier_name="item">
        <span v-if="item.supplier != null">{{
          item.supplier.supplier_name
        }}</span>
        <span v-else>-</span>
      </template>
    </base-data-table-cards>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { debounce } from "lodash";

export default {
  name: "InventoryList",
  watch: {
    options: {
      handler() {
        this.fetchInventorys();
      },
      deep: true,
    },
  },
  components: {
    brand: () => import("@/views/brand/BrandList"),
    category: () => import("@/views/category/CategoryList"),
    supplier: () => import("@/views/supplier/SupplierList"),
    inventory: () => import("@/views/inventory/InventoryNew"),
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("inventorys.add_file"),
          align: "center",
          sortable: false,
          value: "product_image",
        },
        {
          text: this.$t("inventorys.product_cody"),
          align: "center",
          sortable: false,
          value: "product_cody",
        },
        {
          text: this.$t("product.description"),
          align: "center",
          sortable: false,
          value: "description",
        },
        // {
        //   text: this.$t("brand"),
        //   align: "center",
        //   sortable: false,
        //   value: "brand.brand_name",
        // },
        // {
        //   text: this.$t("category"),
        //   align: "center",
        //   sortable: false,
        //   value: "category.category_name",
        // },
        // {
        //   text: this.$t("inventorys.supplier"),
        //   align: "center",
        //   sortable: false,
        //   value: "supplier.supplier_name",
        // },
        {
          text: this.$t("product.price"),
          align: "center",
          sortable: false,
          value: "product_price",
        },

        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      headers_small: [
        { value: "product_image", cols: 2 },
        { value: "description" },
        { value: "product_price" },
      ],
      items: null,
      filters: {
        search: "",
        dates: "",
      },
      sortBy: "Nombre",
      rowsPerPageItems: [10, 25, 50, 100, 200],
      options: {},
      loading: true,
      totalInventory: null,
      count: 0,
    };
  },
  mounted() {
    this.fetchInventorys();
    this.fetchInventorysCount();
    if (this.$route.params.popup) {
      this.$refs.supliers.dialog = true;
    }
  },
  methods: {
    clearMinStock() {
      this.$confirm(this.$t("clear_min_stock")).then(() => {
        this.reduceMinStock().then(() => {
          this.reload();
        });
      });
    },
    reload() {
      this.fetchInventorys();
      this.fetchInventorysCount();
    },
    ...mapActions("inventory", [
      "getInventorys",
      "getInventorysCounts",
      "deleteInventory",
      "archiveInventory",
      "reduceMinStock",
    ]),

    fetchInventorys() {
      this.loading = true;
      this.getInventorys({
        pagination: this.options,
        filters: this.filters,
      }).then((inventory) => {
        //console.log(inventory);
        this.items = inventory.data;
        this.totalInventory = inventory.total;
        this.loading = false;
      });
    },
    fetchInventorysCount() {
      this.getInventorysCounts({
        pagination: this.options,
        filters: { ...this.filters, min_stock: true },
      }).then((inventory) => {
        //console.log(inventory);
        this.count = inventory;
      });
    },
    del(product) {
      if (product.inventory_move.length)
        this.$confirm(this.$t("archive_product_ask")).then(() => {
          this.archiveInventory(product.id).then(() => {
            this.fetchInventorys();
            this.fetchInventorysCount();
            this.$alert(this.$t("archived_ok"));
          });
        });
      else
        this.$confirm(this.$t("archived_ok")).then(() => {
          this.deleteInventory(product.id).then(() => {
            this.fetchInventorys();
            this.fetchInventorysCount();
            this.$alert(this.$t("delete_ok"));
          });
        });
    },
    searchFilter: debounce(function (val) {
      this.fetchInventorys();
    }, 1000),
  },
};
</script>
<style lang="sass">
#inventory-list

.filters .svg-icon, .filters .v-icon
  fill: var(--v-primary-base)
  color: var(--v-primary-base)
  height: 20px

.filters .v-label
  color: white
.v-application .col-6
  //padding-right: 12px !important

.invList
  height: 30px
  .v-list-item__icon
    margin-right: 5px !important

.invList .v-icon, .invList .svg-icon
  margin-top: -3px
  color: var(--v-gris1-lighten2)

  @media (min-width: 800px)
    .shorter
      max-width: 170px

#table_inventory
  td
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
@media (min-width: 960px)
  #table_inventory
    td:nth-child(2)
      max-width: 30px
</style>
