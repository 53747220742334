var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"4"}},[_c('v-text-field',{staticClass:"ml-auto filters search",attrs:{"outlined":"","dense":"","prepend-inner-icon":"mdi-magnify","label":_vm.$t('search'),"hide-details":"","single-line":""},on:{"input":_vm.searchFilter},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),(_vm.count > 0)?_c('v-col',{staticClass:"no-wrap",attrs:{"cols":"6","md":"4"}},[_c('v-btn',{attrs:{"color":"red","outlined":_vm.filters.min_stock !== true,"elevation":"0","height":"30px"},on:{"click":function($event){_vm.filters.min_stock = true;
          _vm.reload();}}},[_vm._v(" "+_vm._s(_vm.$t("inventorys.low_stock", { count: _vm.count }))+" "),(_vm.filters.min_stock)?_c('v-btn',{attrs:{"icon":"","color":"black"},on:{"click":function($event){$event.stopPropagation();_vm.filters.min_stock = false;
            _vm.reload();}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.clearMinStock($event)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$delete")])],1)],1)],1):_vm._e()],1),_c('brand',{ref:"brands",on:{"refresh":_vm.fetchInventorys}}),_c('category',{ref:"categorys",on:{"refresh":_vm.fetchInventorys}}),_c('inventory',{ref:"inventorys",on:{"refresh":_vm.fetchInventorys}}),_c('base-data-table-cards',{attrs:{"id":"table_inventory","headers":_vm.headers,"headers_small":_vm.headers_small,"data":_vm.items,"totalItems":_vm.totalInventory,"title":_vm.$t('inventory')},on:{"update":_vm.fetchInventorys},scopedSlots:_vm._u([{key:"item_product_image",fn:function(item){return [(item.url)?_c('img',{attrs:{"src":item.url,"height":"50px"}}):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$camera")])]}},(_vm.$vuetify.breakpoint.mdAndUp)?{key:"item_product_price",fn:function(item){return [_vm._v(_vm._s(_vm.$n(item.product_price, "currency"))+" ")]}}:{key:"item_product_price",fn:function(item){return [_c('span',[_c('b',[_vm._v(_vm._s(_vm.$n(item.product_price, "currency")))])])]}},{key:"item_actions",fn:function(item){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"large":""}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-horizontal")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"invList",on:{"click":function($event){return _vm.$router.push({
                name: 'inventoryView',
                params: { id: item.id },
              })}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("$eye")])],1),_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t("view", { name: _vm.$tc("product") })))])])],1),_c('v-list-item',{staticClass:"invList",on:{"click":function($event){return _vm.del(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("$delete")])],1),_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t(!item.inventory_move.length ? "delete" : "archivate", { name: _vm.$tc("product"), })))])])],1)],1)],1)]}},{key:"item_brand_brand_name",fn:function(item){return [(item.brand != null)?_c('span',[_vm._v(_vm._s(item.brand.brand_name))]):_c('span',[_vm._v("-")])]}},{key:"item_category_category_name",fn:function(item){return [(item.category != null)?_c('span',[_vm._v(_vm._s(item.category.category_name))]):_c('span',[_vm._v("-")])]}},{key:"item_supplier_supplier_name",fn:function(item){return [(item.supplier != null)?_c('span',[_vm._v(_vm._s(item.supplier.supplier_name))]):_c('span',[_vm._v("-")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }